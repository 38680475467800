import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IsCurrentUserPipe } from './is-current-user.pipe';
import { NotCurrentUserPipe } from './not-current-user.pipe';
import { UserCurrentRoutePipe } from './user-current-route.pipe';
import { UserRoutePipe } from './user-route.pipe';

@NgModule({
  declarations: [IsCurrentUserPipe, NotCurrentUserPipe, UserRoutePipe, UserCurrentRoutePipe],
  exports: [NotCurrentUserPipe, IsCurrentUserPipe, UserRoutePipe, UserCurrentRoutePipe],
  imports: [CommonModule],
})
export class UserPipesModule {}
