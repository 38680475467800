import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MediaObserverModule } from '@app/core/media-observer';
import { NotificationPreviewItemModule } from '@app/features/notifications/notification-preview-item/notification-preview-item.module';
import { UserAvatarModule } from '@app/features/user/user-avatar/user-avatar.module';
import { UserPipesModule } from '@app/features/user/user-pipes/user-pipes.module';
import { ButtonsModule } from '@app/shared/components/buttons/buttons.module';
import { OptionModule } from '@app/shared/components/forms/option/option.module';
import { LetModule } from '@app/shared/directives/let/let.module';
import { BasePipesModule } from '@app/shared/pipes/base-pipes/base-pipes.module';
import { SvgIconComponent } from 'angular-svg-icon';

import { HeaderComponent } from './header.component';
import { HeaderMobileMenuComponent } from './header-mobile-menu/header-mobile-menu.component';
import { HeaderNotificationsComponent } from './header-notifications/header-notifications.component';
import { HeaderProfileComponent } from './header-profile/header-profile.component';

@NgModule({
  declarations: [HeaderComponent, HeaderProfileComponent, HeaderMobileMenuComponent, HeaderNotificationsComponent],
  imports: [
    CommonModule,
    ButtonsModule,
    RouterModule,
    SvgIconComponent,
    CdkConnectedOverlay,
    CdkOverlayOrigin,
    BasePipesModule,
    OptionModule,
    ReactiveFormsModule,
    LetModule,
    UserAvatarModule,
    MediaObserverModule,
    NotificationPreviewItemModule,
    UserPipesModule,
  ],
  exports: [HeaderComponent],
})
export class HeaderModule {}
