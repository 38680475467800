import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@app/core/auth';
import { filter, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserCurrentRedirectGuard {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authService.profile$.pipe(
      map((profile) => profile?.slug),
      filter(Boolean),
      map((slug) => {
        const url = state.url.replace('/profile', `/users/${slug}`);
        this.router.navigateByUrl(url);
        return false;
      }),
    );
  }
}

export const userCurrentRedirectGuardCanActivate: CanActivateFn = (route, state) =>
  inject(UserCurrentRedirectGuard).canActivate(route, state);
