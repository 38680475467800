export enum AuthApiExceptionCodeEnum {
  /** Некорректные данные для входа */
  InvalidCredentials = 'auth.InvalidCredentials',
  /** Email не подтверждён */
  EmailNotConfirmed = 'auth.EmailNotConfirmed',
  /** Email уже подтвержден */
  EmailAlreadyConfirmed = 'auth.EmailAlreadyConfirmed',
  /** User already exist */
  AlreadyExist = 'auth.AlreadyExist',
  /** Пользователь не найден */
  UserNotFound = 'auth.UserNotFound',
  /** Пользователь заблокирован */
  UserBlocked = 'auth.UserBlocked',
  /** Некорректный токен для подтверждения */
  InvalidConfirmationToken = 'auth.InvalidConfirmationToken',
  /** Пароли не должны совпадать */
  PasswordShouldBeDifferent = 'auth.PasswordShouldBeDifferent',
  /** Провайдер для входа через внешний сервис не найден */
  ExternalProviderNotFound = 'auth.ExternalProviderNotFound',
  /** Вход через внешний сервис не доступен */
  ExternalAuthNotAvailable = 'auth.ExternalAuthNotAvailable',
  /** Не удалось определить почту при входе через внешний сервис */
  ExternalAuthEmailNotDefined = 'auth.ExternalAuthEmailNotDefined',
  /** Пользователь не зарегестрирован при входе через внешний сервис */
  ExternalAuthUserNotFound = 'auth.ExternalAuthUserNotFound',
  /** Сессия входа через внешний сервис не найдена */
  ExternalAuthSessionNotFound = 'auth.ExternalAuthSessionNotFound',
  /** Ошибка провайдера входа через внешний сервис */
  ExternalAuthProviderError = 'auth.ExternalAuthProviderError',
}
