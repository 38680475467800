import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userRoute',
})
export class UserRoutePipe implements PipeTransform {
  public transform(slug: string): string[] {
    return ['/users', slug || ''];
  }
}
