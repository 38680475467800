import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '@app/core/auth';
import { map, Observable } from 'rxjs';

@Pipe({
  name: 'userCurrentRoute',
})
export class UserCurrentRoutePipe implements PipeTransform {
  constructor(private readonly authService: AuthService) {}

  public transform(childRoute: string[]): Observable<string[]> {
    return this.authService.profile$.pipe(
      map((profile) => (profile ? ['/users', profile.slug, ...childRoute] : ['/profile', ...childRoute])),
    );
  }
}
